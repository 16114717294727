import rough from '../vendor/rough';

export default class RoughBackground {
    constructor(el) {
        this.el = el;
        this.drawRoughStuff()
    }

    drawRoughStuff() {
        const height = this.el.parentNode.offsetHeight;
        const width = this.el.parentNode.offsetWidth;
        
        const rc = rough.svg(this.el);
        const node = rc.rectangle(5, 5, width - 10, height - 10, {
            stroke: 'rgba(200, 208, 218, .3)',
            strokeWidth: 2,
            fillStyle: 'zigzag',
            fill: 'rgba(200, 208, 218, .3)',
            fillWeight: 2,
            hachureGap: 5,
        });
        this.el.appendChild(node);
        this.el.style.zIndex = "-1";

        this.el.setAttribute('aria-hidden', true);
        
        return this;
    }
}