import rough from '../vendor/rough';

export default class RoughSeperator {
    constructor(el) {
        this.el = el;
        this.init();
    }

    init() {
        this.el.style.cssText = `
            display: block;
            margin: auto;
            width: 60%;
            height: 35px;
        `

        this.handleDrawSeperator = this.drawSeperator.bind(this);

        this.drawSeperator();
        this.attachHandlers();
    }

    drawSeperator() {
        this.el.innerHTML = '';

        const rect = this.el.getBoundingClientRect();

        const height = rect.height;
        const width = rect.width;

        const rc = rough.svg(this.el);
        const node = rc.rectangle(4,4,width - 8, height - 8, {
            stroke: 'rgba(200, 246, 150, .6)',
            strokeWidth: 5,
            fillStyle: 'zigzag',
            fill: 'rgba(200, 246, 150, .6)',
            fillWeight: 4,
            hachureGap: 10,
        });
        this.el.appendChild(node);
    }

    attachHandlers() {
        window.addEventListener('resize', this.handleDrawSeperator);
    }
}