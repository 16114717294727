import { makeACoolProfileBorder } from './profilePic/profilePic';
import RoughBackground from './roughBackground/roughBackground';
import RoughSeperator from './roughSeperator/roughSeperator';

function ready(fn) {
    if (document.attachEvent ? document.readyState === "complete" : document.readyState !== "loading") {
        fn();
    } else {
        document.addEventListener('DOMContentLoaded', fn);
    }
}

ready(() => {
    makeACoolProfileBorder();

    const boxListElements = document.querySelectorAll('.box-list > li > svg');
    [...boxListElements].map(el => {
        new RoughBackground(el);
    });

    const hrElements = document.querySelectorAll('.js-seperator');
    [...hrElements].map(el => new RoughSeperator(el));
});

