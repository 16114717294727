import rough from '../vendor/rough';

export function makeACoolProfileBorder() {
    const profilePic = document.querySelector('.js-profilePic');
    const profilePicSVG = document.querySelector('.js-profilePicSVG');

    function drawRoughProfileOutline() {
        profilePicSVG.innerHTML = '';
        const rc = rough.svg(profilePicSVG);

        function performDraw() {
            // get profile dimensions
            const height = profilePic.height;
            const width = profilePic.width;
    
            let node = rc.rectangle(5, 5, width, height, { 
                roughness: 2, 
                strokeWidth: 2,
                // fill: 'rgba(200,220,250,.2)',
                // fillWeight: 4
            });
            profilePicSVG.appendChild(node);
        }

        if (profilePic.complete) {
            performDraw();
        } else {
            profilePic.addEventListener('load', performDraw);
        }
    }

    window.addEventListener('resize', drawRoughProfileOutline);

    drawRoughProfileOutline();
}